import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ContactUs from './components/ContactUs/ContactUs';
import TechSpec from './components/TechSpec/TechSpec';
import Pavers from './components/Pavers/Pavers';
import WallConnectorPage from './components/PaversDetail/PaversDetail';
import HomePage from './components/HomePage/HomePage';
import DetailPage from './components/DetailPage/DetailPage';

function App() {
  return (
    <div className="App">
      <Router> 
        <Routes>
          <Route path="/" element={<HomePage/>}/>
          <Route path="/Contact" element={<ContactUs/>}/>
          <Route path="/TechnicalSpecification" element={<TechSpec/>}/>
          <Route path="/Pavers" element={<Pavers/>}/>
          <Route path="/WallConnectorPage" element={<DetailPage/>}/>
          <Route path="/DetailPage" element={<WallConnectorPage/>}/>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
