import React from 'react'
import { selectProducts } from '../../features/product/productSlice'
import { useSelector } from 'react-redux'
import MenuItems from './MenuItems'
import style from './Navbar.module.css'

const Navbar = () => {
  const products = useSelector(selectProducts);
  return (
    <div className={style.navbarStyle1}>
      <MenuItems products={products} />
    </div>
  );
}

export default Navbar;