import React, { useState , useEffect } from 'react';
import './colorbox.css';

const ColorBox = ({ images }) => {
  const [selectedImage, setSelectedImage] = useState(null);

  useEffect(() => {
    // Set the initial selected image to the first image in the array
    if (images.length > 0) {
      setSelectedImage(images[0]);
    }
  }, [images]);

  const handleImageSelect = (image) => {
    setSelectedImage(image);
  };

  return (
    <div className="color-box">
      <div className="color-preview col-6 mx-auto">
        <img src={selectedImage} alt="Selected Image" />
      </div>

      <div className="color-swatches col-6 mx-auto">
      <div className='color-text-heading'>
         <div className='paver-size'> 12 x 12 
         <span className='available'>available colors:</span>
         </div>
      <div className="color-swatch-container">
        {images.map((image, index) => (
          <img
            key={index}
            className={`color-swatch`}
            src={image}
            alt={`Image ${index}`}
            onMouseEnter={() => handleImageSelect(image)}
          />
        ))}
        </div>
      </div>

      </div>
    </div>
  );
};

export default ColorBox;
