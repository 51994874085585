import React from 'react'
import Navbar from '../Navbar/Navbar'
// import Carousel from '../Carousel/Carousel';
import Banner from '../Banner/Banner';

import ColorBox from '../ColorBox/ColorBox';
import ColorDimension from '../ColorDimension/ColorDimension';

import NavbarBottom2 from '../Navbar2/NavbarBottom'
const DetailPage = () => {
  
    const colorImages = [
        'images/Old-Miami.avif',
        'images/Old-Canada.avif',
        'images/New-Miami.avif',
        'images/Old-Canada.avif',
        'images/Old-Miami.avif',
        'images/New-Miami.avif',
        'images/Old-Miami.avif',
        'images/Old-Canada.avif',
        'images/New-Miami.avif',
      ];

  return (
    <>
    <Navbar />
    {/* <Carousel /> */}
    <Banner />
     <ColorBox images={colorImages} />
     <ColorDimension />
    <NavbarBottom2 />
  </>
);
}
export default DetailPage