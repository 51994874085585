import React, { useState } from 'react';
import './detail.css';
// import { Link } from 'react-router-dom';
import Slider from 'react-slick';
// import SliderThumbs from 'react-slick-slider-thumbs';
import Navbar from '../Navbar/Navbar'
// import Navbar2 from '../Navbar2/Navbar'
// import { Fade } from "react-awesome-reveal";
import NavbarBottom2 from '../Navbar2/NavbarBottom'
function QuantityPickerAndAddToCart() {
  const [quantity, setQuantity] = useState(1);

  const handleDecreaseQuantity = () => {
    setQuantity(quantity - 1);
  };

  const handleIncreaseQuantity = () => {
    setQuantity(quantity + 1);
  };    

  const handleAddToCart = () => {
    console.log('Adding to cart:', quantity);
  };

  return (
    <div className="product__container__details__quantity__select">
      <div className="quantity-picker-container">
        <button type="number" className="quantity-picker-button" disabled={quantity === 1} onClick={handleDecreaseQuantity}>
          -
        </button>
        {/* <div className="tds-form-input tds-form-input--default quantity-picker-input"> */}
        <input
  className="btn btn-lg btn-dark tds-form-input-text"
  type="number"
  max="12"
  min="1"
  value={quantity}
  onChange={(e) => setQuantity(Number(e.target.value))}
/>

        {/* </div>   */}
        <button className="quantity-picker-button" disabled={quantity === 12} onClick={handleIncreaseQuantity}>
          +
        </button>
      </div>
      <div className="quantity-picker-tip"></div>
      <div className='d-flex'>
      <button className="btn-purchasable tds-btn tds-btn--primary tds-btn--width-full addToCartBtn" onClick={handleAddToCart}>
        ADD TO CART
      </button>  
      </div>
      
    </div>
  );
}

const images = [
  { id: 1, src: 'images/slider1.avif', alt: 'Image 1' },
  { id: 2, src: 'images/slider2.avif', alt: 'Image 2' },
  { id: 3, src: 'images/slider3.avif', alt: 'Image 3' },
  { id: 4, src: 'images/slider4.avif', alt: 'Image 4' },
  { id: 5, src: 'images/slider5.avif', alt: 'Image 5' },
];
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000
  // ... other settings
};
const WallConnectorPage = () => {
  const [quantity, setQuantity] = useState(1);

  const handleDecreaseQuantity = () => {
    setQuantity(Math.max(quantity - 1, 1)); // Ensure quantity doesn't go below 1
  };

  const handleIncreaseQuantity = () => {
    setQuantity(quantity + 1);
  };

  const handleAddToCart = () => {
    console.log('Adding to cart:', quantity);
  };
  const [selectedImage, setSelectedImage] = useState(images[0]); // Initial image

const handleImageClick = (image) => {
  setSelectedImage(image);
};


  return (
    <div className="wall-connector-page">
      <Navbar />
      <div className="product-information">
        <div className="container">
          <div className="row">
            {/* <div className="col col-lg-8">
              <div className='product-image'>
              <img src="images/wall-connector.avif" alt="Tesla Wall Connector" />
              </div>
              <Slider {...settings}>
                {images.map((image) => (
                  <div key={image.id}>
                    <img src={image.src} alt={image.alt} className="image-style" />
                  </div>
                ))}
              </Slider>
            </div> */}
            <div className="col col-lg-8">
              <div className="product-image">
                <Slider {...settings}>
                  <img src={selectedImage.src} alt={selectedImage.alt} className="large-image" />
                </Slider>
              </div>

              <div className='row'>
                {Array.from({ length: 5 }, (_, index) => (
                  <div className="small-image" key={index}>
                    <Slider {...settings}>
                    <img src={selectedImage.src} alt={selectedImage.alt}  />
                    </Slider>
                  </div>
                ))}
              </div>
            </div>

            <div className="col col-lg-4">
              <div className="detail-text">
                <div className="wall-connector">Wall Connector</div>
                <div className="price">
                  $475
                </div>
                <br />
                <div className="quantity">Quantity</div>
                <QuantityPickerAndAddToCart
                  quantity={quantity}
                  setQuantity={setQuantity}
                  handleAddToCart={handleAddToCart}
                />
                <strong className="sub-heading">Description</strong>
                <p>WALL CONNECTOR IS THE MOST CONVENIENT CHARGING SOLUTION FOR HOUSES, APARTMENTS, HOSPITALITY PROPERTIES AND WORKPLACES.</p>
                <strong className="sub-heading">Features</strong>
                <ul className='listing'>
                  <li>Up to 44 mi of range added per hour at 11.5 kW / 48 amp output</li>
                  <li>Auto-sensing handle to open charge port</li>
                  <li>Monitor and manage your charging schedule and usage from the Tesla app</li>
                  <li>Wi-Fi connectivity for over-the-air updates, remote diagnostics and access controls</li>
                  <li>Versatile indoor / outdoor design</li>
                  <li>Minimize install costs and maximize charging speed using Power Management features</li>
                  <li>24-foot cable length</li>
                  <li>Four-year warranty for residential use</li>
                </ul>

                  <strong className="sub-heading">Incentives</strong>
                  <div className="local-electricity">Local Electricity Incentives</div>
                  <strong className="sub-heading">Installation Guidance and Support</strong>
                  <p>Wall Connector must be installed by a qualified electrician. Visit our <div className="link">Find a Certified Installer</div> page to request a quote from one of the 1,000+ Tesla Certified Installers.</p>
                  <strong className="sub-heading">Support</strong>
                  <p>For more information about the Tesla Wall Connector, please visit our support page.</p>
                  <strong className="sub-heading">Commercial properties</strong>
                  <p>PROPERTY MANAGERS, DEVELOPERS AND COMMERCIAL REAL ESTATE OWNERS CAN JOIN OUR GROWING NETWORK OF<div className="link">CHARGING PARTNERS</div> BY OFFERING ON-SITE EV CHARGING. INSTALL WALL CONNECTOR AS A PUBLIC OR PRIVATE AMENITY TO BENEFIT FROM PAY-PER-USE FUNCTIONALITY AND 24/7 REMOTE MONITORING WHILE ATTRACTING NEW AND REPEAT CUSTOMERS WITH FAST EV CHARGING..</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      
        <NavbarBottom2 />
    </div>
  );
};

export default WallConnectorPage;
