import React from 'react'
import style from './Navbottom.module.scss'

const NavbarBottom2=(props)=> {
  return (
    <div className={style.nav_bottom} style={props.belongTo&&{position:'relative',backgroundColor:'white',marginTop:'40px'}}>
        {/* <ul>
            <li>Gem Pavers © 2024</li>
            <li>Privacy & Legal</li>
            <li>Locations</li>
        </ul> */}
        <div className='footer'>
        <div className='container-fluid'>
          <div className='row'>
            <div className="col-6 col">
              <img src="images/footerleft.png" alt="" className={style.footerLeft}/>
            </div>
            <div className="col-6 col">
              <img src="images/footerright.png" alt="" className={style.footerRight}/>
            </div>
          </div>
        </div>
        </div>
    </div>
  )
}

export default NavbarBottom2
