import React, { useState, useEffect } from 'react'
import Navbar from '../Navbar/Navbar'
import SplitPane from 'react-split-pane';
import Pane from 'react-split-pane/lib/Pane';
// import NavbarBottom from '../Navbar/NavbarBottom';
import NavbarBottom2 from '../Navbar2/NavbarBottom'


import style from './Contact.module.scss'
const image = 'images/cars.jpeg'

const email = 'images/email.webp'
const phone = 'images/phone.webp'
const fax = 'images/fax.webp'
const location = 'images/location.webp'
const time = 'images/time.webp'

const ContactUs = () => {

  const [ismobile, setIsmobile] = useState(false);
  useEffect(() => {
    listenTosize();
    window.addEventListener('resize', listenTosize);
    return () =>
      window.removeEventListener('resize', listenTosize);

  }, [])
  const listenTosize = () => {
    const windwidth = window.innerWidth;
    // console.log(windwidth);
    if (windwidth < 500) {
      setIsmobile(true)
      // console.log(ismobile)
    }
    else {
      setIsmobile(false)
      // console.log(ismobile)

    }

  }
  const headingStyle = {
    marginLeft: '18%',
    // marginBottom: '8%'
  };
  const liStyle = {
    marginBottom: '-10%'
  }
  return (
    <div style={{ height: '100vh' }}>
      <Navbar />
      {/* <div style={{ height: '45%' }}>
        <img src={image} className={style.back_img} alt="" />
      </div> */}
      {/* <div  style={{ height: '55%' }}> */}
        {ismobile && <SplitPane split='horizontal' className={style.contact_container}>
          <Pane initialSize="50%" className={style.contact_box}>
          <ul className={style.info} style={liStyle} >
                <li><h3 style={headingStyle}>Gem Paver Systems</h3></li>
                <li><img src={phone} alt="phone" width={20} /> 305 805 0004</li>
                <li><img src={fax} alt="fax" width={20} /> 305 805 0004</li>
                <li><img src={location} alt="location" width={20}/> 9845 Northwest 118th Wy, Medley, FL 33178</li>
                <li><img src={email} alt="email" width={20}/> sales@gempavers.com</li>
                <li><img src={time} alt="time" width={20}/> Monday-Friday: 8-4 <br></br><span className='saturday'>Saturday: 8-1</span></li>
              </ul>
          </Pane>
          <Pane initialSize='50%'>
            <iframe title='location_info' className={style.map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3018.6179144835073!2d-80.36388338433792!3d25.877312738444573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9bb639152d361%3A0x8fcbf266ae7097d9!2s9845%20NW%20118th%20Way%2C%20Medley%2C%20FL%2033178%2C%20USA!5e0!3m2!1sen!2sru!4v1670124541499!5m2!1sen!2sru" width="600" height="450" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
          </Pane>
        </SplitPane>}
        {
          !ismobile &&
          <SplitPane split='vertical' className={style.contact_container}>
            
            <Pane initialSize='50%'>
              <iframe title='location_info' className={style.map} src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3018.6179144835073!2d-80.36388338433792!3d25.877312738444573!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d9bb639152d361%3A0x8fcbf266ae7097d9!2s9845%20NW%20118th%20Way%2C%20Medley%2C%20FL%2033178%2C%20USA!5e0!3m2!1sen!2sru!4v1670124541499!5m2!1sen!2sru" width="600" height="450" style={{ border: '0' }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
            </Pane>
            <Pane initialSize="50%" className={style.contact_box}>
              <ul className={style.info} >
                <li><h3>Gem Paver Systems</h3></li>
                <li><img src={phone} alt="phone" width={20} /> 305 805 0004</li>
                <li><img src={fax} alt="fax" width={20} /> 305 805 0004</li>
                <li><img src={location} alt="location" width={20}/> 9845 Northwest 118th Wy, Medley, FL 33178</li>
                <li><img src={email} alt="email" width={20}/> sales@gempavers.com</li>
                <li><img src={time} alt="time" width={20}/> Monday-Friday: 8-4 <br></br><span className='saturday'>Saturday: 8-1</span></li>
              </ul>
            </Pane>
          </SplitPane>
        }
      {/* </div> */}

      <NavbarBottom2 />
    </div>
  )
}

export default ContactUs;
