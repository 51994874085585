import React, { useState, useRef, useEffect } from 'react';
import './newhomepage.css';

const NewHome = () => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(true);
    useEffect(() => {
        // Start playing the video when the component mounts
        videoRef.current.play();
    }, []);
    const handlePlayPause = () => {
        if (videoRef.current.paused) {
            videoRef.current.play();
            setIsPlaying(true);
        } else {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    return (
        <div className="video-background">
            <video
                ref={videoRef}
                loop
                autoPlay
                controls={false} 
                muted
                onClick={handlePlayPause} // Play/pause when clicking on the video
            >
                <source src="images/Home_video.mp4" type="video/mp4" />
                Your browser does not support the video tag.
            </video>
            <div className="image-overlay">
                <img src="images/video_text.png" alt="Overlay" className="video-text" />
            </div>
            {/* Play/Pause button */}
            <button
                className={`play-pause-button ${isPlaying ? 'pause' : 'play'}`}
                onClick={handlePlayPause}
            >
                {isPlaying ? (
                    <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-stop" viewBox="0 0 16 16">
                        <path fill="white" d="M3.5 5A1.5 1.5 0 0 1 5 3.5h6A1.5 1.5 0 0 1 12.5 5v6a1.5 1.5 0 0 1-1.5 1.5H5A1.5 1.5 0 0 1 3.5 11V5zM5 4.5a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 .5.5h6a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5H5z" />
                    </svg>
                ) : (
                    <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-play" viewBox="0 0 16 16">
                        <path fill="white" d="M10.804 8 5 4.633v6.734L10.804 8zm.792-.696a.802.802 0 0 1 0 1.392l-6.363 3.692C4.713 12.69 4 12.345 4 11.692V4.308c0-.653.713-.998 1.233-.696l6.363 3.692z" />
                    </svg>
                )}
            </button>
        </div>
    );
};

export default NewHome;
