import React from 'react'
import './HomePage.css'
import PorscheSocial from '../PorscheSocial/PorscheSocial'
import Discover from '../Discover/Discover'
import Navbar from '../Navbar/Navbar'
import NewHome from '../NewHomePage/NewHome'
import NavbarBottom2 from '../Navbar2/NavbarBottom'
const HomePage = () => {
  return (
    <>
    <Navbar />
    <NewHome />
    <PorscheSocial />
    <Discover />
    <NavbarBottom2 />
  </>
);
}
export default HomePage