import React from 'react';
// import { Link } from 'react-router-dom';
import './pavers.css';
// import Navbar2 from '../Navbar2/Navbar'
import Navbar from '../Navbar/Navbar'

// import { Fade } from "react-awesome-reveal";
import NavbarBottom2 from '../Navbar2/NavbarBottom'
// import NavbarBottom from '../Navbar/NavbarBottom'
import { useNavigate } from 'react-router-dom'
// import pavers from '../img/pavers.webp'
// const pavers = 'images/pavers.avif'
const productsData = [
  { id: 1, title: '4 x 8', image: 'images/4x8.avif', link: '/WallConnectorPage' },
  { id: 2, title: 'Old Miami', image: 'images/Old-Miami.avif', link: '/WallConnectorPage' },
  { id: 3, title: 'New Miami', image: 'images/New-Miami.avif', link: '/WallConnectorPage' },
  { id: 4, title: 'Old Canada', image: 'images/Old-Canada.avif', link: '/WallConnectorPage' },
  { id: 5, title: '12 x 12', image: 'images/12x12.avif', link: '/WallConnectorPage' },
  { id: 6, title: '8 x 16', image: 'images/8x16.avif', link: '/WallConnectorPage' },
  { id: 7, title: '6 x 9', image: 'images/6x9.avif', link: '/WallConnectorPage' },
  { id: 8, title: '4 x 12', image: 'images/4x12.avif', link: '/WallConnectorPage' },
  { id: 9, title: 'Floridian', image: 'images/Floridian.avif', link: '/WallConnectorPage' },
  { id: 10, title: '8 x 8', image: 'images/8x8.avif', link: '/WallConnectorPage' },
  { id: 11, title: 'Wedge', image: 'images/wedge.avif', link: '/WallConnectorPage' },
  { id: 12, title: 'Turfblock', image: 'images/Turfblock.avif', link: '/WallConnectorPage' },
  { id: 13, title: 'Gem Hat', image: 'images/Gem-Hat.avif', link: '/WallConnectorPage' },
];

const Pavers = () => {
  const navigate = useNavigate()

    return (
        <div className="paversBackground">
          <Navbar />
          
          <div className="pavers">
            {/* <div className="category__heading">Table of Contents</div> */}
            {/* <div className="category__title">Classic</div> */}
            <div className="product-grid">
              {productsData.map((product) => (
                <div key={product.id} className="product-card" onClick={() => navigate(product.link)}>
                  <img src={product.image} alt={product.title} className="product-grid-image" />
                  <div className="product-text">
                    <p>{product.title}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <NavbarBottom2 />
        </div>
      );
};
export default Pavers;