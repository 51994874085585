import React from 'react'
import './Discover.css'
import { useNavigate } from 'react-router-dom'

const discoverData =[
    { id: 1, title: 'Wedge',image: 'images/wedge.avif', link: '/Pavers'},
    { id: 2, title: '8x8',image: 'images/8x8.avif', link: '/Pavers'},
    { id: 3, title: 'Floridian',image: 'images/Floridian.avif', link: '/Pavers'},
]

const Discover = () => {
    const navigate = useNavigate()
  return (
    <div className='discover-section'>
        <div className='discover-header'>Discover</div>
        <div className='discover'>
            <div className="discover-grid">
                {discoverData.map((discover) => (
                <div key={discover.id} className="discover-card" onClick={() => navigate(discover.link)}>
                  <img src={discover.image} alt={discover.title} className="discover-grid-image" />
                  {/* <div className='discover-card-links'>
                        <div className='discover-card-text'>{discover.title}</div>
                        <img src={LinkArrowRed} alt='arrow' className='discover-card-arrow'/>
                  </div> */}
                </div>
              ))}
            </div>
        </div> 
    </div>
  )
}

export default Discover