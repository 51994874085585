import React from 'react';
import './colordimension.css';

const ColorDimension = () => {
    const selectedImage = ['images/color-dimension.jpg'];

  return (
    <div className="color-dimesion">
      <div className="color-dimesion-preview col-6">
        <img src={selectedImage} alt="Selected Image" />
      </div>

      <div className="color-description col-6">
        <div className='color-property'>
          <div className='color-text'>Thickness</div> 
          <div className='color-text'>Dimensions</div>
          <div className='color-text'>SF diver Pallet</div> 
          <div className='color-text'>Pallet Wallet</div>
          <div className='color-text'>Texture</div>         
        </div>
      </div>
    </div>
  );
};

export default ColorDimension;
