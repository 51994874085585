import React, { useState, useEffect } from 'react';
import './Banner.css'; // Import your CSS file

const Banner = () => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const photos = [
    // Replace these with the paths to your actual photos
    'images/Old-Canada.avif',
    'images/New-Miami.avif',
    'images/Old-Miami.avif',
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length);
    }, 3000); // Change photo every 3 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []); // Run useEffect only once on component mount

  const handleNextPhoto = () => {
    setPhotoIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  const handlePrevPhoto = () => {
    setPhotoIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
  };

  return (
    <div className="banner">
      <div className="slider">
        <button className="arrow left-arrow" onClick={handlePrevPhoto}>
          &lt;
        </button>
        <img
          src={photos[photoIndex]}
          alt="Banner Image"
          className="banner-image"
        />
        <button className="arrow right-arrow" onClick={handleNextPhoto}>
          &gt;
        </button>
      </div>
    </div>
  );
};

export default Banner;
