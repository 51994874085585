import React from 'react'
import {Link} from 'react-router-dom'
import style from './MenuItems.module.css'
const MenuItems = props => {
  return (
    <div className={style.header}>
      <Link  to={`/Pavers`}><img src="images/models.png" alt="Models" className={style.modelImage} /></Link>
      <Link  to={`/`}><img src="images/logo.png" alt="Logo" className={style.logoImage} /></Link>
      <Link  to={`/Contact`}><img src="images/contact.png" alt="Contact" className={style.contactImage} /></Link>
    </div>
  )
}

export default MenuItems